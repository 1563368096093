<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> /
        <span class="active">Fiscalia TV</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center">Fiscalia TV</h3>
      </template>
    </titulo-seccion>
    <section class="noticias">
      <div class="container" v-for="(itemv, index) in items" :key="index">
        <div class="row">
          <div class="col-md-6">
            <!-- <input v-model="buscar" type="search" @keyup.enter="buscarVideoList()" class="form-control" placeholder="Buscar"> -->
          </div>
          <div class="col-md-6 d-grid gap-2 d-md-flex justify-content-md-end">
            <button type="button" class="btn btn-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-border-all"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 0h16v16H0V0zm1 1v6.5h6.5V1H1zm7.5 0v6.5H15V1H8.5zM15 8.5H8.5V15H15V8.5zM7.5 15V8.5H1V15h6.5z"
                />
              </svg>
            </button>
            <button type="button" class="btn btn-outline-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-list-ul"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="row my-4">
          <div
            v-for="(item, index) in itemv.play_list_item"
            :key="index"
            class="col-lg-4 col-md-4 col-sm-2 col-xs-12"
          >
            <div class="card card-video mb-4">
              <div class="video-portada">
                <img
                  v-lazy="item.thumbnail"
                  @click="selectVideo(item)"
                  class="img-fluid img-responsive cursor md-round"
                  :alt="item.title"
                  :title="item.title"
                  v-b-modal.modal-video
                />
                <a
                  @click="selectVideo(item)"
                  v-b-modal.modal-video
                  class="btn btn-icon btn-play"
                  :style="{
                    '--background-color-btn':
                      config.disenio.datos.color_primario,
                  }"
                >
                  <i class="icon icon-white">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0V24L20 12L0 0Z" fill="white" />
                    </svg>
                  </i>
                </a>
              </div>
              <div class="card-body p-0 pt-3">
                <p class="card-title">
                  <strong class="card-title">{{ item.title }}</strong>
                </p>
                <p class="card-text text-truncate">{{ item.description }}</p>
                <div
                  class="d-flex justify-content-between align-items-center text-start"
                >
                  <small class="text-start text-muted">{{
                    item.published_at
                  }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- modal video -->
    <b-modal
      id="modal-video"
      ref="my-modal"
      header-bg-variant="dark"
      header-text-variant="light"
      header-class="text-small"
      title-class="text-truncate"
      size="lg"
      body-class="p-0"
      centered
      :hide-footer="true"
    >
      <template #modal-title>
        <h6>{{ title }}</h6>
      </template>
      <b-embed
        v-if="video"
        class="border"
        type="iframe"
        :allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        :src="video"
      />
    </b-modal>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
//import PageHeader from '/src/components/PageHeader.vue';
import { BModal, VBModal, BEmbed } from "bootstrap-vue";
import TituloSeccion from "@/views/secciones/TituloSeccion.vue";

export default {
  components: {
    //PageHeader,
    BModal,
    BEmbed,
    TituloSeccion,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      titulo: "Videos",
      items: [],
      video: "",
      buscar: "",
      title: "",
    };
  },

  created: function () {
    this.listarVideos();
  },
  watch: {
    buscar: function (val) {
      if (val == "" || val.length == 0) {
        this.listarVideos();
      }
    },
  },

  methods: {
    onkey(event) {
      alert(event);
    },
    listarVideos() {
      var url = "admin/videos/playlist";
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectVideo(data) {
      this.title = data.title;
      if (data.opcion_id == 1) {
        this.video = `https://www.youtube.com/embed/${data.videoId}`;
      } else {
        this.video = data.videoId;
      }
    },
    buscarVideoList() {
      this.listarVideos();
    },
  },
  computed: {
    config() {
      return this.$store.state.config;
    },
  },
};
</script>

<style>
.cursor {
  cursor: pointer;
}

.ratio {
  border-radius: 0;
}
</style>
